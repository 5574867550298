import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { ChangeEvent, KeyboardEvent } from 'react';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  openModal: boolean;
  onHandleCloseModal: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  searchInput: string;
  searchValue: string;
  [key: string]: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End

export default class AdvancedSearchModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      loading: false,
      searchInput: "",
      searchValue: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    const {searchInput} = this.state;
    if (event.key === 'Enter' && searchInput.trim() !== '') {
      this.setSearchValue(searchInput.trim())
      event.preventDefault();
    }
  };

  setSearchValue = (value: string) => {
    this.setState({searchValue: value , searchInput : ""});
  }

  handleDeleteChip = () => {
    this.setState({searchValue : ""});
  };

  handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({searchInput :event.target.value});
  };
  // Customizable Area End
}
