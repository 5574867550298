Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.circularMarketPLaceTitle ="CIRCULAR MARKETPLACE";
exports.pillowCaseTitle = "PILLOWCASE PARADISE";
exports.ModalContent = "To enjoy all the features of ProcureCircular, please log in or create an account."
exports.pillowCaseDescription = "Don't throw away those old pillowcases; turn them into a paradise of possibilities! Transform them into reusable grocery bags with a touch of sewing magic. These eco-friendly bags are not only durable but also a stylish way to reduce single-use plastic and make a positive impact on the environment.";
exports.circularMarketPLaceDescription ="Be sustainable by using our premium, verified circular materials, sold at a low-cost."
// Customizable Area End