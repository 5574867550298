Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.advancedsearchApiContentType = 'application/json'
exports.getAdvancedSearchApiEndPoint = 'advanced_search/search'
exports.errorTitle = 'Error'
exports.tipsList = [
  {
    title1: 'Tip 1:',
    title2: 'Choose Certified Fabrics',
    details: [
      'Look for fabrics with certifications like GOTS or OEKO-TEX to ensure they meet high environmental and ethical standards.',
    ]
  },
  {
    title1: 'Tip 2:',
    title2: 'Choose Certified Fabrics',
    details: [
      'Look for fabrics with certifications like GOTS or OEKO-TEX to ensure they meet high environmental and ethical standards.',
    ]
  },
  {
    title1: 'Tip 3:',
    title2: 'Choose Certified Fabrics',
    details: [
      'Look for fabrics with certifications like GOTS or OEKO-TEX to ensure they meet high environmental and ethical standards.',
    ] 
  }
]

exports.recentSearchsTitle = "Recent Searches"
exports.clearAll = "Clear all"
exports.recentSearchList = [
  "Material name",
  "Material name",
  "Material name",
  "Material name",
  "Material name",
]

exports.popularSearchsTitle = "popular searches"
exports.popularSearchList = [
  "Popular search",
  "Popular search",
  "Popular search",
  "Popular search",
]

exports.reltedSearchList = [
  {type: "search_result" ,value : "Double Face Wool & Silk Crepe"},
  {type: "search_result" ,value : "Double Face Wool & Silk Crepe"},
  {type: "search_result" ,value : "Double Wool"},
  {type: "search_result" ,value : "Silk Crepe"},
  {type: "search_result" ,value : "Silk Crepe"},
  {type: "search_result" ,value : "Silk Crepe"},
  {type: "previous_search" ,value : "Organic Silk"},
  {type: "previous_search" ,value : "Organic Silk"},
  {type: "previous_search" ,value : "Organic Silk"},
  {type: "previous_search" ,value : "Organic Silk"},
]
// Customizable Area End
