import React from "react";

// Customizable Area Start
import {
  Box,
  Dialog,
  DialogContent,
  Container,
  TextField,
  InputAdornment,
  Button,
  Typography,
  Chip
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AdvancedSearchModalController, {
  Props,
  configJSON,
} from "./AdvancedSearchModalController.web";
import Loader from "../../../components/src/Loader.web";
import { colors } from "../../../blocks/utilities/src/Colors";
import { BgImageGreen, ClockIcon, CloseIcon, logo, MapIcon, RoundClose, SearchIcon, SearchIcon2, SearchIcon3, ThreedotIcon } from "./assets";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
// Customizable Area End


export default class AdvancedSearchModal extends AdvancedSearchModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { loading, searchInput, searchValue } = this.state;
    const { openModal, onHandleCloseModal } = this.props;
    return (
      <>
        <Loader loading={loading} />
        <DialogContainer
          fullScreen
          open={openModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <SearchHeader>
            <Box className={"logoContainer"}>
              <img src={logo} className="logo_image" />
              <Box className={"closeIconMobile"}>
                <img
                  id="close-icon"
                  onClick={onHandleCloseModal}
                  alt="close-icon"
                  src={CloseIcon}
                />
              </Box>
            </Box>
            <Box className="centerContainer">
              <div className="threedotInner">
                <span><img src={ThreedotIcon} /></span>
              </div>
              <div className="searchContainer">
                <span className="searchIcon"><img src={SearchIcon} /></span>
                <TextField
                  className="searchInput"
                  data-test-id="searchInput"
                  id="input-with-icon-textfield"
                  placeholder={searchValue ? "" : "Search by Material type, location, end-use"}
                  value={searchInput}
                  onChange={this.handleSearchChange}
                  onKeyPress={this.handleKeyPress}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment style={{ display: searchValue ? "flex" : "none" }} position="start">
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                          <CustomChip
                            data-test-id="searchBadge"
                            label={searchValue}
                            onDelete={this.handleDeleteChip}
                            color="primary"
                            deleteIcon={<img src={RoundClose} />}
                          />
                        </Box>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src={MapIcon} />
                      </InputAdornment>
                    ),
                    sx: {
                      '&:before': {
                        borderBottom: '1px solid #EDF0F240 !important',
                      },
                      '&:hover:not(.Mui-disabled):before': {
                        borderBottom: '1px solid #EDF0F240 !important',
                      },
                      '&:after': {
                        borderBottom: '1px solid #EDF0F240 !important',
                      },
                    },
                  }}
                  variant="standard"
                />
              </div>

              <Button
                className="searchBtn"
                data-test-id="search_button"
              >
                SEARCH
              </Button>
              <div className="threedotInner">
                <span><img src={ThreedotIcon} /></span>
              </div>
            </Box>
            <div className="rightIconContainer">
              <Box className={"closeIcon"}>
                <img
                  id="close-icon"
                  onClick={onHandleCloseModal}
                  alt="close-icon"
                  src={CloseIcon}
                />
              </Box>
            </div>

          </SearchHeader>

          <>
            <DialogContent dividers>
              <MainContainer>
                <Box className="recentSearchContainer">
                  <div className="tipsContainer">
                    <Carousel
                      showArrows={false}
                      showStatus={false}
                      showThumbs={false}
                      useKeyboardArrows={false}
                      autoPlay={false}
                      autoFocus={false}
                      emulateTouch={true}
                      swipeable={true}
                    >
                      {configJSON.tipsList.map((tip: { title1: string, title2: string, details: string[] }) => (
                        <Box className="tipItem" key={tip.title1}>
                          <Typography className="title1">{tip.title1}</Typography>
                          <Typography className="title2">{tip.title2}</Typography>
                          {tip.details.length > 0 && tip.details.map((details) => (
                            <Typography className="detailsPara">{details}</Typography>
                          ))}
                        </Box>
                      ))}
                    </Carousel>
                  </div>
                  <div className="bindRecentSearch">
                    <div className="recentSearchesInner">
                      <Box className="titleAndClearOuter">
                        <Typography className="title">{configJSON.recentSearchsTitle}</Typography>
                        <Typography className="clearAll">{configJSON.clearAll}</Typography>
                      </Box>
                      {configJSON.recentSearchList.map((searchItem: string) => (
                        <Box className="searchItemContainer" key={searchItem}>
                          <img src={SearchIcon2} />
                          <Typography className="searchItem">{searchItem}</Typography>
                        </Box>
                      ))}
                    </div>

                    <div className="recentSearchesInner">
                      <Typography className="title">{configJSON.popularSearchsTitle}</Typography>
                      {configJSON.popularSearchList.map((searchItem: string) => (
                        <Box className="searchItemContainer" key={searchItem}>
                          <img src={SearchIcon2} />
                          <Typography className="searchItem">{searchItem}</Typography>
                        </Box>
                      ))}
                    </div>
                  </div>

                </Box>
                <Box className="filterContainer">
                  {searchInput && <Box className="slContainer">

                    {configJSON.reltedSearchList.map((searchItem: { type: string, value: string }) => (
                      <Box className="slItem" onClick={this.setSearchValue.bind(null, searchItem.value)}>
                        <img src={searchItem.type === "search_result" ? SearchIcon3 : ClockIcon} />
                        <Typography className="searchText">{searchItem.value}</Typography>
                      </Box>
                    ))}
                  </Box>}
                </Box>
              </MainContainer>
            </DialogContent>
          </>
        </DialogContainer>
      </>

    );
    // Customizable Area End
  }
}

// Customizable Area Start
const DialogContainer = styled(Dialog)({
  "& .logoContainer": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '@media (max-width: 1023px)': {
      width: "100%",
      marginBottom: "58px",
    },
    '@media (max-width: 600px)': {
      marginBottom: "66px",
    },
    "& .closeIconMobile": {
      height: "30px",
      width: "30px",
      '@media (min-width: 1024px)': {
        display: "none"
      },
      "& img": {
        cursor: "pointer",
        height: "30px",
        width: "30px",
      },
    },
  },
  "& .MuiDialog-paperWidthSm": {
    boxShadow: "none",
    padding: "0px",
    margin: "0px",
    "& .MuiDialogContent-root,& .MuiDialogActions-root, & .MuiDialogTitle-root":
    {
      padding: "0",
      justifyContent: "center",
    },
    "& .MuiDialogContent-root": {
      borderColor: "#2D6A4D",
      backgroundImage: `url(${BgImageGreen})`,
      backgroundSize: "cover",
      backgroundColor: "#162B26",
      "@media (max-width: 480px)": {
        padding: "40px 12px 0 12px",
      },
    },
    "& .MuiDialogActions-root": {
      padding: 0,
      justifyContent: "end",
    },
  },
});
const MainContainer = styled(Box)({
  height: "100%",
  display: "flex",
  "@media (max-width: 1023px)": {
    flexDirection: "column-reverse",
    padding: "32px",
    height: "100%",
    boxSizing: "border-box",
    overflow: "auto",
    paddingTop: "20px",
  },
  "@media (max-width: 600px)": {
    padding: "20",
  },
  "& .recentSearchContainer": {
    width: "278px",
    borderRight: "1px solid #2D6A4D",
    padding: "31px 24px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    gap: "39px",
    overflow: "auto",

    "@media (max-width: 1023px)": {
      width: "100%",
      height: "auto",
      border: "1px solid #2D6A4D",
      overflow: "unset",
    },

    "& .tipsContainer": {
      padding: "26px",
      boxSizing: "border-box",
      border: "1px solid",
      borderImageSlice: 1,
      borderImageSource: "linear-gradient(71.92deg, rgba(216, 196, 135, 0) 44.6%, #D8C487 100%)",
      background: "linear-gradient(179.92deg, rgba(15, 23, 42, 0) 0.07%, rgba(0, 0, 0, 0.8) 146.35%)",
      "& .title1": {
        color: "#6B9581",
        fontSize: "10px",
        lineHeight: "20px",
        fontWeight: 390,
        marginBottom: "4px",
      },
      "& .title2": {
        color: "#E7DBB6",
        fontSize: "14px",
        lineHeight: "14px",
        fontWeight: 390,
        marginBottom: "12px",
      },
      "& .detailsPara": {
        color: "#C8DBC9",
        fontSize: "12px",
        lineHeight: "15.6px",
        fontWeight: 400,
      },

      "& .carousel": {
        display: "flex",
        flexDirection: "column-reverse",
      },
      "& .control-dots": {
        marginTop: "12px",
        position: "unset",  
        textAlign: "left",
        display: "flex",
        gap: "6px",
      },
      "& .dot": {
        margin: "0px",
        height: "4px",
        width: "4px",
        background: '#000000',
        "&.selected": {
        background: "#C29E40",
      },
      },
      "& .slide": { 
        textAlign: "unset",
      },

    },
    "& .bindRecentSearch": {
      gap: "39px",
      display: "flex",
      flexDirection: "column",
      "@media (max-width: 1023px)": {
        display: "flex",
        justifyContent: "space-between",
      },
      "@media (max-width: 600px)": {
        flexDirection: "column",
      },
    },
    "& .recentSearchesInner": {
      boxSizing: "border-box",
      "@media (max-width: 1023px)": {
        width: "50%",
      },
      "@media (max-width: 600px)": {
        width: "100%",
      },
      "& .titleAndClearOuter": {
        display: "flex",
        gap: "25px",
      },
      "& .clearAll": {
        color: "#CBAE5B",
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: 390,
        display: "none"
      },
      "& .title": {
        color: "#CBAE5B",
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: 390,
        marginBottom: "39px",
        textTransform: "uppercase",
      },
      "& .searchItemContainer": {
        display: "flex",
        gap: "12px",
        marginBottom: "12px",
        "& img": {
          height: "20px",
          width: "20px",
        },
        "& .searchItem": {
          display: "flex",
          gap: "8",
          color: "#8AAA91",
          fontSize: "14px",
          lineHeight: "19.6px",
          fontWeight: 400,
        },
      },

      "& .detailsPara": {
        color: "#C8DBC9",
        fontSize: "12px",
        lineHeight: "15.6px",
        fontWeight: 400,
      },

    },
  },
  "& .filterContainer": {
    flex: 1,
    width: "100%",
    padding: "47px 15px",
    boxSizing: "border-box",
    "@media (max-width: 1023px)": {
      padding: "0",
    },
    "& .slContainer": {
      background: "linear-gradient(180deg, rgba(82, 138, 111, 0) 0%, rgba(82, 138, 111, 0.5) 100%)",
      borderRadius: "16px",
    },
    "& .slItem": {
      display: "flex",
      height: "38px",
      gap: "8px",
      alignItems: "center",
      padding: "0 10px",
      borderBottom: "1px solid #162A26",
      borderRadius: "8px",
      cursor: "pointer",
      "& img": {
        height: "14px",
        width: "14px",
      },
      "& .searchText": {
        color: "#E0EEDF",
        fontSize: "16px",
        lineHeight: "22.4px",
        fontWeight: 420,
      },
    },
  },
});
const SearchHeader = styled(Container)({
  padding: "16px 24px 16px 69px !important",
  boxSizing: "border-box",
  maxWidth: "100% !important",
  width: "100% !important",
  minHeight: "139px",
  backgroundColor: "#162B26",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "10px",
  '@media (max-width: 1023px)': {
    flexDirection: "column",
    padding: "12px 12px 30px 32px !important",
    minHeight: "auto",
  },
  '@media (max-width: 600px)': {
    padding: "7px 6px 15px 20px !important",
  },
  "& .logo_image": {
    height: "16px",
    '@media (max-width: 1200px)': {
      height: "13px",
    },
    '@media (max-width: 900px)': {
      height: "9px",
      width: "137px",
    },
    '@media (max-width: 600px)': {
      height: "6.72px",
      width: "103px",
    },
  },

  "& .centerContainer": {
    height: "100%",
    display: "flex",
    flex: "1",
    alignItems: "center",
    justifyContent: "center",
    '@media (max-width: 1023px)': {
      width: "100%",
      flexDirection: "column",
      gap: "24px",
      paddingRight: "20px",
      boxSizing: "border-box",
    },
    '@media (max-width: 600px)': {
      paddingRight: "14px",
    },
    "& .searchBtn": {
      width: "120px",
      minWidth: "120px",
      height: "32px",
      color: colors().white,
      backgroundColor: "#BA902F",
      fontSize: "14px",
      lineHeight: "22px",
      fontWeight: 420,
      borderRadius: "26px",
      border: "1px solid #C29E40",
      boxSizing: "border-box",
      marginRight: "51px",
      "&.Mui-disabled": {
        backgroundColor: "#F1F5F9",
        color: "#64748B",
      },
      '@media (max-width: 1400px)': {
        marginRight: "40px",
      },
      '@media (max-width: 1300px)': {
        marginRight: "30px",
      },
      '@media (max-width: 1250px)': {
        marginRight: "15px",
      },
      '@media (max-width: 1023px)': {
        width: "100%",
        margin: 0
      },
    },
  },
  "& .threedotInner": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    '@media (max-width: 1023px)': {
      display: "none",
    },
  },

  "& .searchContainer": {
    height: "100%",
    width: "100%",
    maxWidth: "535px",
    padding: "0px 11px 0px 25px",
    display: "flex",
    alignItems: "center",
    gap: "11px",
    paddingLeft: "40px",
    boxSizing: "border-box",
    '@media (max-width: 1400px)': {
      padding: "0px 11px 0px 20px",
    },
    '@media (max-width: 1340px)': {
      maxWidth: "500px",
    },
    '@media (max-width: 1300px)': {
      padding: "0px 11px 0px 15px",
    },
    '@media (max-width: 1250px)': {
      padding: "0px 11px 0px 0px",
      maxWidth: "470px",
    },
    '@media (max-width: 1023px)': {
      width: "100%",
      maxWidth: "100%",
      padding: "0px",
    },
  },
  "& .searchIcon": {
    height: "32.63px",
    width: "32.63px",
    marginRight: "9px",
  },
  "& .searchInput": {
    width: "100%",
    "& .MuiInputBase-root": {
      borderRadius: "0",
      borderColor: "#E2E8F0",
      paddingRight: "16px",
      '@media (max-width: 1023px)': {
        paddingRight: "0px",
      },
      "& .MuiInputBase-input": {
        fontSize: "16px",
        fontWeight: "420",
        lineHeight: "19.2px",
        color: "#FFFFFF",
        padding: "8px 0px",
        paddingTop: "0px",
        textTransform: "uppercase",
      }
    },
    '& .MuiInputBase-input::placeholder': {
      opacity: 0.5,
      fontSize: "16px",
      fontWeight: "420",
      lineHeight: "19.2px",
      wordBreak: "break-all",
      overflow: "hidden" as const,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      textTransform: "uppercase",
      '@media (max-width: 600px)': {
        fontSize: "12px",
        lineHeight: "14.4px",
      },
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#fef3c7',
      '& fieldset': {
        borderColor: 'blue',
        border: "none",
      },
    },
  },
  "& .rightIconContainer": {
    display: "flex",
    height: "100%",
    boxSizing: "border-box",
    alignItems: "start",
    padding: "16px 24px 0 0",

    '@media (max-width: 1023px)': {
      display: "none",
    },
  },
  "& .closeIcon": {
    cursor: "pointer",
    height: "30px",
    width: "30px",
    "& img": {
      cursor: "pointer",
      height: "30px",
      width: "30px",
    },
  },
}
);

const CustomChip = styled(Chip)(({ theme }) => ({
  backgroundColor: '#CBAE5B',
  color: '#292524',
  fontWeight: 420,
  fontSize: '16px',
  lineHeight: "19.2px",
  borderRadius: '6px',
  height: '27px',
  width: '164px',
  padding: '4px 8px',
  paddingRight: '2px',
  marginBottom: '9px',
  justifyContent: 'space-between',
  '.MuiChip-deleteIcon': {
    color: '#292524',
    fontSize: '12.75px',
  },
  '.MuiChip-label': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
}));
// Customizable Area End
